<template>
    <custom-vue-table class="pl-3 pr-3" title="Invoices" :fields="fields" :url="listUrl" :per-page="10" ref="table"
                      :extra-params="{ start_date, end_date }">
        <template #filters>
            <div class="btn-group ml-3">
                <drop-btn text="Filters" class="ml-3" icon="fa fa-filter" size="md" dropdown-width="25r">
                    <div class="p-3">
                        <validated-input type="date" label="Start Date" name="Start Date"
                                               v-model="start_date"/>
                        <validated-input type="date" label="End Date" name="End Date"
                                               v-model="end_date"/>
                    </div>
                </drop-btn>
            </div>
        </template>
        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn color="primary" class="btn-basic-b" size="xs" @click="viewDetails(rowData)" text="View"/>
            </div>
        </template>
    </custom-vue-table>
</template>

<script>
import urls from '../../../../data/urls';

export default {
    name : 'ListingComponent',
    data () {
        return {
            start_date : '',
            end_date   : '',
            listUrl    : urls.salesAdmin.invoices.list,
            fields     : [
                {
                    name      : '__slot:no',
                    sortField : 'no',
                    title     : 'Sl No'
                },
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'created_date',
                    sortField : 'created_date',
                    title     : 'Created Date'
                },
                {
                    name      : 'customer',
                    sortField : 'customer',
                    title     : 'Customer'
                },
                {
                    name      : 'sub_total',
                    sortField : 'sub_total',
                    title     : 'Sub Total'
                },
                {
                    name      : 'total_amount',
                    sortField : 'total_amount',
                    title     : 'Total Amount'
                },
                {
                    name      : 'status',
                    sortField : 'status',
                    title     : 'Status'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }

            ]
        };
    },
    methods : {
        getSlNo (rowData, items) {
            for (let i = 0; i <= items.length; i++) {
                if (items[i].id === rowData.id) {
                    return i + 1;
                }
            }
        },
        viewDetails (rowData) {
            this.$router.push('/sales/invoice/' + rowData.id + '/details/');
        }
    }
};
</script>
